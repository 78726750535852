.tv {
    z-index: 2;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
}

@-webkit-keyframes breathing {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }

    25% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }

    100% {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

.tv-contents {
    z-index: 1;
    width: 400px;
    height: 300px;
    padding: 0px;
    margin: 0px;
    background-color: white;
    background-image: url('../images/evilbrave-standby.png');
    background-position: center center;
    background-size: 80%;
    background-repeat: no-repeat;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.screen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: repeating-linear-gradient(
        0deg,
        rgba(255, 0, 0, 0.2),
        transparent 2px,
        rgba(255, 0, 0, 0.5) 10px,
        transparent 10px
    );
    opacity: 0.25;
    filter: blur(1px) contrast(110%) brighness(5) saturation(8);
    animation: jitter 5s ease-in-out infinite;
    animation-delay: 2s;
    transform-origin: center center;
}

.screen:before {
    content: '';
    width: 100%;
    height: 100%;
    top: -5px;
    left: -5px;
    position: absolute;
    background: repeating-linear-gradient(
        0deg,
        rgba(0, 0, 255, 0.2),
        transparent 2px,
        rgba(0, 255, 0, 0.5) 10px,
        transparent 10px
    );
    filter: brighness(10) saturation(10);
    animation: jitter 3s ease-in-out infinite;
    animation-delay: 1s;
    transform-origin: center center;
}

.screen:after {
    content: '';
    width: 100%;
    height: 100%;
    top: -2px;
    left: -2px;
    position: absolute;
    animation: jitter 2s ease-in-out infinite;
    background: repeating-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        transparent 2px,
        rgba(255, 255, 255, 0.5) 10px,
        transparent 10px
    );
    transform-origin: center center;
}

@keyframes jitter {
    0% {
        margin-top: 10px;
        margin-left: 5px;
    }

    20% {
        margin-top: -10px;
        margin-left: -5px;
    }

    25% {
        margin-top: 2px;
        margin-left: 3px;
    }

    26% {
        margin-top: 4px;
        margin-left: -1px;
    }

    27% {
        margin-top: 0px;
        margin-left: 0px;
    }

    30% {
        margin-top: 10px;
        margin-left: 5px;
    }

    50% {
        margin-top: -1px;
        margin-left: 1px;
    }

    61% {
        margin-top: 1px;
        margin-left: -1px;
    }

    63% {
        margin-top: -30px;
        left: -70%;
        transform: skewX(-10deg);
        width: 250%;
        height: 250%;
    }

    67% {
        margin-top: -10px;
        margin-left: -15px;
    }

    69% {
        margin-top: 5px;
        margin-left: 5px;
    }

    70% {
        top: -100px;
        margin-left: 5px;
        transform: skewY(10deg);
        width: 250%;
        height: 250%;
    }

    80% {
    }

    81% {
        margin-top: 0px;
        margin-left: 0px;
    }

    100% {
        margin-top: 10px;
        margin-left: 5px;
    }
}
