@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    background: black;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', monospace;
    overflow-y: scroll;
}

code {
    font-family: 'Roboto', monospace;
}
